import React, { useState, useEffect } from "react";
import Layout from "@layout";
import SEO from "@components/seo";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../components/shared/button";
import { useSelector, useDispatch } from "react-redux";
import { orderBy } from "lodash";
import PageBreadcrumb from "../components/pagebreadcrumb";
import { Auth, API } from "aws-amplify";
import { matchesByPlayerTwo } from "../graphql/queries";
import { useForm } from "react-hook-form";
import { updateMatch, updateUserProfile } from "../graphql/mutations";
import { v4 } from "uuid";
import { setUser } from "../state/userSlice.js";
import DataTable from "react-data-table-component";

const MyAccountPage = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const user = useSelector((state) => state.user.value);
    const dispatch = useDispatch();
    const [unverifiedMatches, setUnverifiedMatches] = useState([]);
    const [selectedRows, setSelectedRows] = React.useState(false);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };
    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    };
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const handleVerify = () => {
        if (window.confirm(`Are you sure you want to verify these matches?`)) {
            let promises = [];
            for (const match of selectedRows) {
                promises.push(
                    API.graphql({
                        query: updateMatch,
                        variables: {
                            input: { id: match.id, verified: true },
                        },
                    })
                );
            }
            Promise.all(promises)
                .then((values) => {
                    reloadMatches();
                    alert("Your matches have been verified!");
                })
                .catch((err) => {
                    console.log(err);
                    alert("Error validating your matches!");
                });
        }
    };
    const reloadMatches = () => {
        Auth.currentAuthenticatedUser()
            .then((userObj) => {
                API.graphql({
                    query: matchesByPlayerTwo,
                    variables: {
                        limit: 2000,
                        filter: { verified: { eq: false } },
                        playerTwoId: userObj.username,
                    },
                })
                    .then((result) => {
                        setUnverifiedMatches(
                            orderBy(
                                result.data.matchesByPlayerTwo.items,
                                "created",
                                "asc"
                            )
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                        alert("Error loading your unverified matches!");
                    });
            })
            .catch((err) => {});
    };
    const onSubmit = (data) => {
        Auth.currentAuthenticatedUser()
            .then((userObj) => {
                API.graphql({
                    query: updateUserProfile,
                    variables: {
                        input: { id: userObj.username, name: data.myName },
                    },
                })
                    .then((result) => {
                        dispatch(
                            setUser({
                                id: userObj.username,
                                email: userObj.attributes.email,
                                profile: result.data.updateUserProfile,
                            })
                        );
                        alert("Your display name has been updated!");
                    })
                    .catch((err) => {
                        for (const error of err?.errors) {
                            console.log(error);
                        }
                        alert("Error updating your display name!");
                    });
            })
            .catch((err) => {});
    };
    useEffect(() => {
        reloadMatches();
    }, []);
    const columns = [
        {
            name: "Match ID",
            selector: (row) => row.id,
        },
        {
            name: "Opponent Name",
            selector: (row) => row.playerOne?.name,
            sortable: true,
        },
        {
            name: "Opponent Score",
            selector: (row) => row.playerOneScore,
            sortable: true,
        },
        {
            name: "Your Score",
            selector: (row) => row.playerTwoScore,
            sortable: true,
        },
    ];

    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO title="My Account" pathname="/my-account" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="My Account"
            />
            <div>
                <div className="container shadow-md rounded bg-white text-gray-700">
                    <div className="px-8 pt-6 pb-8 mb-4">
                        {user?.id && (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h3 className="mb-4">Update your profile</h3>
                                <div className="mb-6">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="myName"
                                    >
                                        Display Name
                                    </label>
                                    <input
                                        {...register("myName", {
                                            required: true,
                                        })}
                                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                        id="myName"
                                        type="text"
                                        placeholder={user?.profile?.name}
                                    />
                                    {errors.myName && (
                                        <span className="text-red-700">
                                            This field is required, and must be
                                            between 0 and 5
                                        </span>
                                    )}
                                </div>
                                <div className="flex items-center justify-between">
                                    <button
                                        onClick={handleSubmit(onSubmit)}
                                        className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                    >
                                        Update Display Name
                                    </button>
                                </div>
                            </form>
                        )}
                        {!user?.id && (
                            <h3>You must be logged in to view your account!</h3>
                        )}
                    </div>
                </div>
                {user?.id && (
                    <div className="container shadow-md rounded bg-white text-gray-700">
                        <div className="px-8 pt-6 pb-8 mb-4">
                            {user?.id && <div></div>}
                            <h3 className="mb-4">Your unverified matches</h3>
                            {selectedRows.length > 0 && (
                                <button
                                    onClick={handleVerify}
                                    className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button"
                                >
                                    Verify Matches
                                </button>
                            )}
                            <DataTable
                                striped
                                noDataComponent="You don't have any matches to verify."
                                columns={columns}
                                data={unverifiedMatches}
                                selectableRows
                                onSelectedRowsChange={handleChange}
                                clearSelectedRows={toggledClearRows}
                                pagination
                            />
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
};

MyAccountPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query MyAccountPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
    }
`;
export default MyAccountPage;
